import axios from 'axios';
import { nanoid } from 'nanoid';

export type ServerInfo = {
  ip: string;
  city: string;
  country: string;
}

export type Status = {
  online: boolean;
  ping: number | null;
  createdAt: number;
}

const API_URL: string = process.env.VUE_APP_API_URL;

export function getServerInfo(): Promise<ServerInfo> {
  return axios
    .get(`${API_URL}/server_info`)
    .then(r => r.data as ServerInfo);
}

export function getStatus(): Promise<Status> {
  return new Promise<Status>(resolve => {
    const createdAt = (new Date()).getTime();
    const requestId = nanoid();
    const url = `${API_URL}/ping?id=${requestId}`;

    axios
      .get(url)
      .then(() => {
        const entry = performance.getEntriesByType('resource').find(e => e.name === url);
        const ping = entry ? Math.round(entry.duration) : null;
        resolve({ online: true, ping, createdAt });
      })
      .catch(() => {
        resolve({ online: false, ping: null, createdAt });
      })
      .then(() => {
        performance.clearResourceTimings();
      });
  });
}
