import { defineStore } from 'pinia';
import { ServerInfo, Status } from '@/app/api';

export const useConnectionStore = defineStore('connection', {
  state: () => ({
    serverInfo: null as ServerInfo | null,
    allStatuses: [] as Status[],
    failedGettingServerInfo: false,
  }),
  getters: {
    currentStatus: (state) => state.allStatuses[state.allStatuses.length - 1],
  },
});
