import axios from 'axios';

export type UserInfo = {
  ip: string;
  city: string;
  country: string;
}

const API_URL = 'https://ipapi.co/json';

export function getUserInfo(): Promise<UserInfo> {
  return axios
    .get(API_URL)
    .then(r => ({
      ip: r.data.ip,
      city: r.data.city,
      country: r.data.country_name,
    }));
}
