// eslint-disable-next-line import/no-webpack-loader-syntax
import Worker from 'worker-loader!./worker';

const worker = new Worker();

const setInterval = (interval: number) =>
  worker.postMessage({
    type: 'setInterval',
    interval,
  });

const onTick = (callback: () => void) => {
  worker.addEventListener('message', callback);
};

export default {
  setInterval,
  onTick,
};
