import { createApp } from 'vue';
import App from './App.vue';
import './registerServiceWorker';
import router from './router';
import { createPinia } from 'pinia';
import naive from 'naive-ui';
import VueApexCharts from 'vue3-apexcharts';

const app = createApp(App);
app.use(router);
app.use(createPinia());
app.use(VueApexCharts);

// TODO: Import every component directly.
// For now install globally until I know what I want.
app.use(naive);

app.mount('#app');
